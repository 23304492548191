<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="100%" viewBox="0 0 1251 384" enable-background="new 0 0 1251 384" xml:space="preserve">
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M220.531342,1.000000
	C221.000000,70.114273 221.000000,139.228546 221.000000,208.671219
	C205.000641,208.671219 189.283157,208.671219 173.223328,208.671219
	C173.223328,158.707443 173.223328,108.792046 173.223328,58.876648
	C172.965790,58.833092 172.708237,58.789532 172.450699,58.745972
	C168.972153,70.673622 165.459091,82.591339 162.023392,94.531319
	C157.216812,111.235451 152.485504,127.961235 147.689713,144.668488
	C142.132309,164.028976 136.425140,183.347595 131.046814,202.757492
	C129.769318,207.367874 127.922745,209.457993 122.713005,209.142258
	C114.081795,208.619156 105.393822,208.895691 96.734024,209.059784
	C93.937447,209.112793 92.704620,208.191132 91.937912,205.471115
	C85.371330,182.175217 78.687569,158.912186 71.981941,135.655716
	C67.211021,119.109192 62.352009,102.588066 57.532757,86.055473
	C54.866821,76.909882 52.201813,67.764030 49.041267,58.680431
	C49.041267,108.553886 49.041267,158.427338 49.041267,208.649933
	C33.377266,208.649933 17.992107,208.649933 2.227706,208.649933
	C2.073427,205.257034 1.917514,201.828186 1.380800,198.199677
	C1.000000,192.977905 1.000000,187.955811 1.383857,182.262421
	C1.845142,179.769501 1.989870,177.947922 1.990240,176.126297
	C2.002103,117.750862 2.000000,59.375431 2.000000,1.000000
	C24.354227,1.000000 46.708454,1.000000 69.648453,1.359311
	C76.839157,23.438314 83.423485,45.164303 90.055344,66.875763
	C95.650238,85.192383 101.305099,103.490662 106.922417,121.800438
	C108.251579,126.132858 109.536606,130.478836 111.211342,136.046707
	C113.787918,127.385445 115.918671,120.232826 118.043472,113.078445
	C126.407768,84.915192 134.794189,56.758469 143.119568,28.583706
	C145.829254,19.413626 148.377762,10.195920 151.000000,0.999999
	C174.020889,1.000000 197.041794,1.000000 220.531342,1.000000
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M1252.000000,257.531342
	C1236.540649,258.000000 1221.081177,258.000000 1205.109375,258.000000
	C1205.109375,237.341705 1205.109375,216.992981 1205.109375,195.307999
	C1197.958862,203.348999 1190.302368,208.579697 1181.161255,210.969055
	C1153.421997,218.219696 1128.151978,214.009995 1107.033325,193.446640
	C1091.575684,178.395370 1084.128540,159.800323 1082.863525,138.236450
	C1081.753540,119.317375 1085.128174,101.558838 1094.065552,84.982918
	C1105.436401,63.894039 1122.903809,51.365192 1146.920166,47.907547
	C1164.516479,45.374195 1180.880127,47.836716 1195.648682,58.008347
	C1199.967896,60.983128 1203.714722,64.788857 1207.967285,68.421570
	C1207.967285,63.108601 1207.967285,57.864532 1207.967285,52.000000
	C1222.555664,52.000000 1236.809204,52.000000 1251.531372,52.000000
	C1252.000000,120.354225 1252.000000,188.708450 1252.000000,257.531342
M1200.533081,151.995224
	C1202.101807,148.453949 1203.932007,145.003204 1205.194580,141.355911
	C1210.895386,124.886658 1202.535767,106.808411 1190.331177,98.260658
	C1175.351562,87.769379 1152.432861,89.687202 1140.565552,103.809219
	C1125.689453,121.511772 1126.262817,143.749603 1142.345215,159.486435
	C1159.233643,176.011978 1187.356689,172.744217 1200.533081,151.995224
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M489.531342,1.000000
	C490.000000,17.792305 490.000000,34.584610 490.000000,51.894329
	C497.362152,51.894329 504.422211,51.894329 511.735260,51.894329
	C511.735260,64.339462 511.735260,76.270813 511.735260,88.722839
	C504.821381,88.722839 497.756683,88.722839 490.211670,88.722839
	C490.211670,128.936523 490.211670,168.636627 490.211670,208.667236
	C474.013855,208.667236 458.301086,208.667236 442.096741,208.667236
	C442.096741,168.947540 442.096741,129.247467 442.096741,89.205414
	C434.349121,89.205414 427.079559,89.205414 419.405823,89.205414
	C419.405823,76.824409 419.405823,64.775337 419.405823,52.203667
	C426.589264,52.203667 433.986725,52.203667 442.000000,52.203667
	C442.000000,35.107384 442.000000,18.522348 442.000000,1.468657
	C457.687561,1.000000 473.375122,1.000000 489.531342,1.000000
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M871.531372,1.000000
	C872.000000,70.109444 872.000000,139.218887 872.000000,208.663986
	C856.683228,208.663986 841.633057,208.663986 826.000000,208.663986
	C826.000000,139.738403 826.000000,70.837860 826.000000,1.468657
	C841.020874,1.000000 856.041809,1.000000 871.531372,1.000000
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M1252.000000,362.531342
	C1232.234985,363.000000 1212.469971,363.000000 1192.353271,363.000000
	C1192.353271,342.664001 1192.353271,322.651703 1192.353271,302.000000
	C1211.947388,302.000000 1231.505005,302.000000 1251.531372,302.000000
	C1252.000000,322.020905 1252.000000,342.041779 1252.000000,362.531342
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M1.000000,282.468658
	C4.797046,282.000000 8.594091,282.000000 12.999995,282.000000
	C12.999995,284.173126 12.999990,285.945801 12.999996,287.718475
	C13.000054,303.205505 12.946926,318.692749 13.021050,334.179443
	C13.062446,342.827972 17.903418,350.494324 24.935219,352.899048
	C33.872330,355.955322 45.930962,355.107605 50.138348,348.065186
	C52.707462,343.764954 54.591496,338.413452 54.785271,333.455994
	C55.376705,318.325165 54.999981,303.156464 55.000000,288.002502
	C55.000004,286.205139 55.000000,284.407776 55.000000,282.307373
	C58.664413,282.307373 61.925522,282.307373 65.999985,282.307373
	C65.999985,283.950897 65.999985,285.697754 65.999985,287.444580
	C65.999985,304.097290 66.005028,320.750000 65.998528,337.402710
	C65.992531,352.760559 54.673508,364.062042 39.207417,363.960724
	C33.941189,363.926239 28.655109,363.655334 23.417822,363.110596
	C10.092492,361.724548 2.497878,350.274231 1.347881,338.193970
	C1.000000,319.645782 1.000000,301.291534 1.000000,282.468658
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M605.454102,385.000000
	C605.000000,358.226288 605.000000,331.452545 605.000000,304.340210
	C608.404114,304.340210 611.462463,304.340210 614.744873,304.340210
	C614.890625,306.027649 615.030212,307.642822 615.234009,310.002319
	C622.434326,302.015381 631.644104,301.766754 640.584167,303.469421
	C651.725403,305.591309 659.480957,315.569946 659.886719,327.270599
	C660.109375,333.693481 660.450745,340.596466 658.459534,346.516388
	C653.513611,361.220490 640.215149,367.281097 625.251221,362.531067
	C621.785767,361.431030 618.752014,358.971283 615.000000,356.842346
	C615.000000,365.729828 615.000000,374.896240 615.000000,384.531342
	C611.969421,385.000000 608.938782,385.000000 605.454102,385.000000
M619.966858,317.555298
	C614.698059,325.655762 613.770752,334.121429 616.905151,343.366913
	C620.636108,354.371979 636.424377,358.852600 643.896606,349.605591
	C648.806213,343.529968 650.188599,336.726868 649.112305,329.004913
	C648.118896,321.878174 644.893616,316.382874 638.551392,313.383118
	C631.942200,310.257172 625.724426,311.906860 619.966858,317.555298
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M251.995438,92.020218
	C263.437469,70.202042 280.508728,55.338795 304.151611,49.567215
	C330.770081,43.069252 355.377625,48.067200 377.102051,65.538895
	C393.048920,78.364067 401.747101,95.442375 405.700500,114.924866
	C407.389740,123.249321 406.670227,132.068008 406.979828,140.664459
	C407.116455,144.458237 404.839447,145.063553 401.535004,145.055191
	C366.373230,144.966248 331.211121,144.999985 296.049103,145.000000
	C294.231628,145.000000 292.414154,145.000000 289.966339,145.000000
	C290.919525,153.338150 294.543274,159.965759 300.558563,164.891495
	C311.907562,174.184860 328.604828,177.360229 342.068268,169.303009
	C345.537415,167.226852 348.196655,163.828293 351.365570,161.203415
	C352.546692,160.225052 354.115326,159.109482 355.517395,159.095734
	C370.839386,158.945740 386.163361,159.001785 402.208954,159.001785
	C390.986298,185.594971 372.830841,204.629440 345.150177,211.583115
	C313.692108,219.485733 285.107758,210.868530 263.795349,186.689957
	C239.144516,158.723969 235.637405,126.377838 251.995438,92.020218
M293.911621,104.458008
	C292.665192,107.169945 291.418793,109.881882 290.072449,112.811256
	C313.446838,112.811256 336.077942,112.811256 358.664734,112.811256
	C358.788361,112.300911 359.007782,111.935463 358.924347,111.662613
	C353.528259,94.017120 338.609222,86.335068 322.612000,87.221085
	C310.759735,87.877525 300.505890,92.919052 293.911621,104.458008
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M1004.094543,49.944405
	C1036.407715,57.751041 1055.995483,78.393791 1063.745605,109.528816
	C1070.607788,137.096878 1065.369385,163.147659 1046.940918,185.286224
	C1034.653564,200.047501 1018.693298,208.998016 999.257629,212.240494
	C979.178223,215.590363 959.980103,213.756149 941.914368,204.837540
	C917.752441,192.909363 904.180115,172.192383 899.261169,146.401749
	C894.759216,122.797188 899.677979,100.541313 913.746338,80.551666
	C925.972290,63.179825 942.777283,53.232418 963.257568,49.030270
	C976.765869,46.258629 990.272217,46.223728 1004.094543,49.944405
M1017.617065,140.866302
	C1021.059448,125.609703 1017.576050,112.540611 1006.097839,101.485779
	C993.164368,89.029320 971.507874,88.590767 958.504944,101.010033
	C948.543884,110.523987 944.404602,121.929794 945.867554,135.832535
	C947.316833,149.606384 959.269775,164.720184 972.518066,167.980988
	C992.630981,172.931381 1011.819580,161.553146 1017.617065,140.866302
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M799.999512,138.719040
	C798.274536,152.943848 794.339294,166.074356 786.618042,177.587509
	C775.592712,194.027344 760.711121,205.507339 741.138794,210.471344
	C718.784180,216.141022 696.989685,215.102219 676.139832,204.951691
	C654.889648,194.606262 641.689148,177.209351 635.108215,154.826447
	C629.671997,136.336868 630.450134,117.746765 637.196167,99.748940
	C644.676270,79.792542 657.938171,64.580948 677.365356,55.357082
	C686.687866,50.930824 696.673523,48.971210 707.033813,47.781193
	C723.850464,45.849586 739.819763,48.418613 754.769470,55.536987
	C778.092468,66.642410 792.136780,85.829041 797.777710,110.745399
	C799.788269,119.625916 799.330933,129.065140 799.999512,138.719040
M680.053772,120.971375
	C680.035156,126.489403 679.595642,132.044540 680.078247,137.518387
	C681.431458,152.867828 695.763672,167.245270 711.265381,168.913391
	C734.794128,171.445312 752.025513,153.650528 752.823120,131.216034
	C753.290100,118.079582 747.621033,106.776405 737.072266,98.802269
	C728.825928,92.568588 719.164856,90.344460 708.771301,92.653954
	C693.650696,96.013802 685.151245,106.180099 680.053772,120.971375
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M539.244629,208.989151
	C538.178772,208.025879 537.077209,207.071823 537.075623,206.115967
	C536.992554,154.979324 537.002197,103.842545 537.002197,52.353065
	C551.951233,52.353065 566.671265,52.353065 581.926208,52.353065
	C581.926208,56.664436 581.926208,61.029556 581.926208,65.394676
	C591.280762,51.202686 604.894897,47.201015 620.750488,46.960712
	C620.750488,63.023792 620.750488,78.627625 620.750488,94.279068
	C614.891846,95.178116 608.909180,95.820862 603.051208,97.055191
	C592.413086,99.296722 585.531372,106.721352 585.100586,117.379707
	C584.390381,134.954895 584.242432,152.555359 584.030151,170.147247
	C583.875854,182.932098 584.000000,195.720291 584.000000,208.997513
	C568.947449,208.997513 554.334839,208.997513 539.244629,208.989151
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M1136.999512,302.000000
	C1143.467773,302.000000 1149.436157,302.000000 1155.699707,302.000000
	C1155.699707,322.389648 1155.699707,342.438080 1155.699707,362.743896
	C1135.640747,362.743896 1115.709473,362.743896 1095.388672,362.743896
	C1095.388672,342.790833 1095.388672,322.740997 1095.388672,302.000000
	C1108.994995,302.000000 1122.747314,302.000000 1136.999512,302.000000
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M999.000000,337.000000
	C999.000000,325.216949 999.000000,313.933929 999.000000,302.327271
	C1019.395325,302.327271 1039.413574,302.327271 1059.716675,302.327271
	C1059.716675,322.364777 1059.716675,342.265961 1059.716675,362.584167
	C1039.785645,362.584167 1019.765991,362.584167 999.000000,362.584167
	C999.000000,354.320770 999.000000,345.910400 999.000000,337.000000
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M903.000000,320.000977
	C903.000000,313.876709 903.000000,308.252136 903.000000,302.316711
	C923.083740,302.316711 942.768860,302.316711 962.727600,302.316711
	C962.727600,322.386780 962.727600,342.287628 962.727600,362.594849
	C943.097107,362.594849 923.410706,362.594849 903.000000,362.594849
	C903.000000,348.633118 903.000000,334.566864 903.000000,320.000977
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M496.000000,341.999939
	C496.000000,335.836639 496.002716,330.173279 495.999329,324.509918
	C495.994629,316.606964 493.653046,313.102448 487.681580,312.049774
	C481.725128,310.999756 474.352356,314.887726 472.321899,320.470612
	C471.497742,322.736542 471.083740,325.269623 471.056915,327.687531
	C470.929565,339.173798 471.000000,350.662292 471.000000,362.574585
	C467.776245,362.574585 464.733124,362.574585 461.000000,362.574585
	C461.000000,354.917053 461.121368,347.149872 460.960602,339.388519
	C460.837677,333.453644 460.197906,327.529114 460.088501,321.594452
	C460.003693,316.996674 457.737579,313.850128 453.754913,312.471893
	C449.282318,310.924103 444.858398,312.280243 441.297577,315.297485
	C437.070251,318.879517 435.020599,323.675903 435.007599,329.249725
	C434.981934,340.232147 435.000031,351.214691 435.000031,362.597717
	C431.731567,362.597717 428.688782,362.597717 425.317841,362.597717
	C425.317841,343.368042 425.317841,324.114380 425.317841,304.429688
	C428.218964,304.429688 431.264526,304.429688 434.729950,304.429688
	C434.853973,305.862213 434.991547,307.451324 435.165741,309.463654
	C446.944122,301.127441 458.496002,298.837952 469.870697,310.638611
	C474.918579,304.190674 482.024841,302.457489 490.299957,302.895691
	C499.633698,303.389984 506.367249,308.200409 506.786774,317.588165
	C507.453552,332.509003 506.964752,347.481506 506.964752,362.714325
	C503.290375,362.714325 500.029694,362.714325 496.000000,362.714325
	C496.000000,356.011597 496.000000,349.255737 496.000000,341.999939
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M701.956848,317.199829
	C697.228271,311.190277 691.115784,311.243103 684.795715,312.703735
	C679.795654,313.859283 676.891113,317.031830 676.781860,322.683289
	C673.349365,322.683289 669.949402,322.683289 666.556763,322.683289
	C665.464233,315.463135 670.339050,306.344666 678.038574,304.832581
	C685.912109,303.286316 694.421265,303.105682 702.359558,304.289276
	C710.208862,305.459656 712.979126,310.558716 712.995239,318.629913
	C713.015198,328.615051 713.147522,338.603485 712.930969,348.583954
	C712.838013,352.865204 713.865601,355.309235 718.834900,354.102295
	C718.834900,357.057983 718.834900,359.684875 718.834900,362.255463
	C710.172974,365.748901 706.252502,364.580200 702.780701,357.173859
	C696.363220,362.173706 689.107605,364.484375 680.996948,364.016907
	C673.094543,363.561462 666.082031,358.795441 664.196472,352.414948
	C662.256470,345.850281 664.136414,337.008636 669.759521,334.136719
	C675.725281,331.089783 682.642761,329.855560 689.206299,328.054382
	C691.871948,327.322845 694.765442,327.429962 697.439636,326.719818
	C701.693359,325.590332 703.394470,321.918365 701.956848,317.199829
M689.469727,354.000763
	C700.390076,351.603058 704.412964,345.506012 702.409546,333.986938
	C695.549866,335.310791 688.533325,336.130676 681.907471,338.201019
	C679.176208,339.054382 676.237305,342.261963 675.358582,345.024109
	C674.711487,347.058563 676.577698,351.308563 678.540771,352.417755
	C681.323608,353.990112 685.225891,353.581146 689.469727,354.000763
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M176.254990,349.738373
	C181.020203,351.491577 185.473511,354.022247 190.029053,354.222015
	C197.118179,354.532928 202.039261,350.528351 203.372116,343.198242
	C206.998856,343.198242 210.293137,343.198242 213.595352,343.198242
	C212.951508,353.560303 206.735352,360.748993 195.148056,363.292145
	C183.391113,365.872528 171.646042,362.505829 165.102127,351.619904
	C158.934464,341.359833 158.567871,318.902069 169.828049,309.480408
	C177.706940,302.887939 186.557373,301.598053 195.804626,303.570251
	C205.182953,305.570343 210.293686,312.848846 212.886703,321.646210
	C214.259781,326.304565 214.659470,331.249817 215.619598,336.759949
	C200.768448,336.759949 186.725571,336.759949 172.639984,336.759949
	C170.805679,342.189209 173.933838,345.684296 176.254990,349.738373
M183.562576,312.820892
	C176.461472,314.857635 171.757797,320.838623 172.652924,326.702576
	C182.678299,326.702576 192.703690,326.702576 202.728302,326.702576
	C202.573547,317.399689 194.459778,311.230469 183.562576,312.820892
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M563.439941,348.057068
	C557.254944,367.685211 531.055969,367.177551 520.735718,358.077698
	C513.319885,351.538940 508.709778,336.315765 512.415710,322.827820
	C518.107605,302.111786 541.305725,297.647125 554.887634,308.018005
	C561.731384,313.243774 563.799377,321.051483 564.945740,329.099854
	C565.288574,331.506744 564.998718,334.003723 564.998718,336.974854
	C550.458984,336.974854 536.382324,336.974854 522.321228,336.974854
	C522.254089,344.965118 527.430603,352.420898 534.024719,353.897919
	C542.858826,355.876617 550.007935,353.515503 553.429749,345.337982
	C555.463074,340.478851 560.099304,344.015839 563.967896,342.929321
	C563.853455,344.882690 563.769897,346.309784 563.439941,348.057068
M545.425781,327.000000
	C548.187744,327.000000 550.949768,327.000000 553.776245,327.000000
	C552.590210,317.915619 546.217590,312.005402 538.077942,312.007294
	C531.569214,312.008789 522.919617,316.977295 522.614563,327.000000
	C529.895081,327.000000 537.169800,327.000000 545.425781,327.000000
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M335.033569,303.000000
	C351.809021,301.415375 364.087219,316.804382 361.721191,336.870667
	C347.641968,336.870667 333.454895,336.870667 319.257385,336.870667
	C319.724487,348.263153 327.144775,356.560028 340.665588,353.796936
	C343.854340,353.145325 348.248657,350.548218 349.112488,347.881958
	C351.410706,340.788361 356.220856,343.405121 360.489685,343.200867
	C362.207947,349.401093 356.270935,358.958771 348.399323,361.709778
	C337.853943,365.395172 327.496704,365.573120 318.133575,358.061401
	C313.267853,354.157715 311.141266,348.828552 309.497803,343.079102
	C306.888611,333.951019 307.708008,325.210876 312.058624,316.823975
	C316.773529,307.734680 324.081085,302.750305 335.033569,303.000000
M343.132507,314.036041
	C332.350067,308.037811 319.977661,315.891266 319.633240,326.643921
	C330.008728,326.643921 340.384369,326.643921 350.881104,326.643921
	C350.611450,321.052765 347.504913,317.544586 343.132507,314.036041
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M389.905243,312.573425
	C382.333801,314.901001 378.040710,320.503967 378.011505,328.095428
	C377.967438,339.550690 378.000031,351.006256 378.000031,362.727478
	C374.261963,362.727478 370.999054,362.727478 367.366455,362.727478
	C367.366455,336.004517 367.366455,309.293732 367.366455,282.290649
	C370.700867,282.290649 373.964844,282.290649 377.837524,282.290649
	C377.837524,291.241638 377.837524,300.263000 377.837524,310.506805
	C384.052795,303.829498 390.901520,302.704437 398.425201,302.923248
	C410.061890,303.261597 415.509613,310.078461 415.862122,319.671875
	C416.381744,333.812134 415.996277,347.985687 415.996277,362.572083
	C412.780151,362.572083 409.736115,362.572083 406.000000,362.572083
	C406.000000,354.248566 406.000031,345.814880 405.999939,337.381165
	C405.999878,333.381836 406.157501,329.374969 405.967896,325.384644
	C405.505829,315.658691 400.887756,310.601501 389.905243,312.573425
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M772.752808,323.746521
	C779.270386,317.568329 785.320984,311.388580 791.931274,305.880432
	C793.862854,304.270874 797.367493,304.414520 800.189453,304.052673
	C802.277832,303.784882 804.428162,304.000000 807.280518,305.143433
	C799.977051,311.824554 792.673584,318.505707 785.060303,325.470276
	C793.150146,337.700104 801.213013,349.889099 809.881897,362.994293
	C805.191223,362.994293 801.722900,363.235504 798.319397,362.870972
	C797.029297,362.732758 795.533325,361.439453 794.736389,360.262787
	C788.677490,351.317261 782.768555,342.270111 776.839050,333.294220
	C770.479614,337.462585 766.607544,342.553467 767.894165,350.623871
	C768.509338,354.482880 768.000000,358.521210 768.000000,362.732086
	C764.588257,362.732086 761.658386,362.732086 758.362488,362.732086
	C758.362488,336.009552 758.362488,309.304871 758.362488,282.299377
	C761.347595,282.299377 764.277039,282.299377 767.805542,282.299377
	C767.805542,297.225555 767.805542,312.245850 767.805542,328.377319
	C769.947754,326.380096 771.223572,325.190613 772.752808,323.746521
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M828.390015,363.993042
	C824.079956,362.689545 820.030579,361.780334 816.405884,360.004517
	C810.545654,357.133392 807.468567,350.116669 808.533997,343.363525
	C813.715210,342.464478 818.331421,341.825195 820.188110,348.944824
	C821.214172,352.879181 829.844971,355.679321 834.840454,354.531097
	C837.810913,353.848328 841.160156,353.425079 843.514465,351.735901
	C848.604309,348.083984 847.518311,342.054779 841.491638,340.083954
	C835.290833,338.056091 828.791565,336.941101 822.590027,334.915039
	C811.472107,331.282776 806.246460,324.740906 810.085083,313.392975
	C812.133240,307.337952 817.651306,304.364502 823.249207,303.610535
	C829.675293,302.744934 836.586365,302.843842 842.881287,304.289490
	C851.248047,306.210938 855.336060,313.166260 854.738281,321.576508
	C849.677368,322.361267 845.312805,323.193817 842.526489,316.552307
	C840.135803,310.853851 825.675598,310.313965 821.537048,315.027954
	C818.984436,317.935516 819.510193,321.771973 823.504883,323.626495
	C827.754822,325.599487 832.486572,326.515900 836.966797,328.016632
	C841.894775,329.667267 847.262146,330.684692 851.554016,333.383698
	C856.895874,336.742950 859.148132,342.381012 857.541016,348.927338
	C855.552612,357.026733 850.119934,361.562927 842.131165,362.916626
	C837.765503,363.656342 833.275391,363.661743 828.390015,363.993042
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M100.189087,303.000000
	C103.637329,303.090881 106.734924,302.702515 109.589455,303.363586
	C117.742409,305.251740 122.508934,310.669556 122.852371,319.977692
	C123.372803,334.082855 122.988350,348.221436 122.988350,362.671326
	C119.253922,362.671326 115.879364,362.671326 112.000000,362.671326
	C112.000000,350.033112 112.000603,337.589233 111.999779,325.145355
	C111.999275,317.590057 110.278992,314.491943 104.987656,312.509308
	C100.552834,310.847595 91.956131,312.729523 89.239670,316.907104
	C87.111694,320.179657 85.362488,324.302704 85.187607,328.127594
	C84.665314,339.550751 85.000000,351.013062 85.000000,362.726715
	C81.265381,362.726715 78.004082,362.726715 74.369766,362.726715
	C74.369766,343.355011 74.369766,323.986603 74.369766,304.307983
	C77.468628,304.307983 80.526230,304.307983 83.780396,304.307983
	C83.912216,306.428284 84.032158,308.357452 84.192726,310.940125
	C88.926315,306.735870 93.146164,302.363434 100.189087,303.000000
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M304.000000,325.148499
	C304.000000,337.902130 304.000000,350.174774 304.000000,362.720764
	C300.279358,362.720764 297.019135,362.720764 293.000031,362.720764
	C293.000031,361.158386 293.000031,359.573273 293.000031,357.988159
	C293.000031,346.506348 292.619354,335.007324 293.138702,323.549042
	C293.465149,316.347321 286.770508,309.995239 278.019501,312.432129
	C270.306091,314.580017 265.703918,320.719177 265.922058,328.699402
	C266.229828,339.959106 266.000000,351.233521 266.000000,362.746887
	C262.225708,362.746887 258.967041,362.746887 255.352707,362.746887
	C255.352707,343.328491 255.352707,323.969482 255.352707,304.304260
	C258.475830,304.304260 261.533264,304.304260 264.751587,304.304260
	C264.932739,306.231659 265.095520,307.963989 265.351288,310.685577
	C274.565521,300.834778 285.156616,300.690857 295.592682,305.528473
	C303.203644,309.056549 303.717957,317.150024 304.000000,325.148499
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M144.774628,287.019409
	C145.828583,288.346161 146.846954,289.635345 146.918915,290.975403
	C147.140274,295.097931 146.999557,299.239929 146.999557,303.784088
	C150.640030,303.784088 153.905411,303.784088 157.584930,303.784088
	C157.584930,306.900513 157.584930,309.610901 157.584930,312.923370
	C154.281082,312.923370 150.892151,312.923370 147.012299,312.923370
	C147.012299,325.619781 146.924438,337.735352 147.164307,349.844452
	C147.185532,350.915710 149.205460,352.546387 150.543015,352.856537
	C152.724045,353.362183 155.107864,352.993103 157.700592,352.993103
	C157.700592,356.334412 157.700592,359.267578 157.700592,363.311096
	C152.217407,362.767334 146.533951,362.964722 141.349731,361.451599
	C137.115189,360.215668 135.984451,355.683441 135.992371,351.296722
	C136.014893,338.810242 136.000275,326.323730 136.000275,313.242981
	C132.767975,313.242981 129.717133,313.242981 126.334633,313.242981
	C126.334633,309.942230 126.334633,307.230438 126.334633,304.102234
	C129.427780,304.102234 132.361801,304.102234 135.884918,304.102234
	C135.884918,298.319733 135.884918,292.927032 135.884918,287.005127
	C139.079514,287.005127 141.692841,287.005127 144.774628,287.019409
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M230.000000,355.915405
	C230.000000,358.374969 230.000000,360.345856 230.000000,363.000000
	C227.900391,363.000000 226.141251,363.000000 224.382095,363.000000
	C222.763336,363.000000 221.144592,363.000000 219.266052,363.000000
	C219.266052,343.268280 219.266052,324.029999 219.266052,304.395325
	C222.278214,304.395325 225.316925,304.395325 228.755249,304.395325
	C228.755249,307.300507 228.755249,310.233704 228.755249,313.683594
	C232.248123,310.473480 234.896851,306.966125 238.416412,305.110382
	C241.842712,303.303864 246.160599,303.188293 250.644516,302.208435
	C250.644516,306.658600 250.644516,310.040253 250.644516,313.728638
	C248.152100,313.925201 245.616440,313.742004 243.304626,314.375549
	C234.531006,316.779999 230.850281,323.143799 230.121445,332.472900
	C229.527084,340.080902 230.000198,347.772278 230.000000,355.915405
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M580.000000,344.039215
	C580.000000,350.490295 580.000000,356.447968 580.000000,362.698792
	C576.656677,362.698792 573.726196,362.698792 570.396240,362.698792
	C570.396240,343.424622 570.396240,324.060364 570.396240,304.346802
	C573.377808,304.346802 576.420776,304.346802 579.892456,304.346802
	C579.892456,307.357727 579.892456,310.244507 579.892456,314.537354
	C585.087341,305.872375 591.489502,302.005463 600.720642,303.265411
	C600.720642,306.687927 600.720642,309.950958 600.720642,313.441925
	C598.269226,313.913239 595.687622,314.175262 593.254211,314.920776
	C585.531372,317.286865 581.480530,322.677246 580.986267,330.747864
	C580.724792,335.017944 580.333008,339.280060 580.000000,344.039215
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M740.812744,305.637085
	C745.100037,304.328705 749.084473,303.250305 753.697327,302.001831
	C753.697327,306.777557 753.697327,310.162872 753.697327,313.666290
	C751.378845,313.920502 749.200684,313.881866 747.180237,314.427521
	C736.894409,317.205292 733.868469,323.374542 733.119202,334.877838
	C732.527039,343.968781 733.000061,353.129150 733.000061,362.627869
	C729.676025,362.627869 726.636658,362.627869 723.294189,362.627869
	C723.294189,343.214630 723.294189,323.861603 723.294189,304.254028
	C726.434143,304.254028 729.357422,304.254028 732.876831,304.254028
	C732.876831,306.967834 732.876831,309.639893 732.876831,312.704956
	C735.776978,310.106903 738.143433,307.987000 740.812744,305.637085
z"/>
</svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
    fontColor: {
      type: String,
    }
  }
}
</script>
